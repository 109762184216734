import React, {useState, useEffect} from 'react';
import {Routes, Route, useNavigate, useParams} from 'react-router-dom';
import {Button} from '@components';
import Reports from './Reports';
import Memories from './Memories';

const Resolved = () => {
  const navigate = useNavigate();
  const {'*': path} = useParams();

  const [type] = path.split('/');

  let Component;
  switch (type) {
    case 'memories':
      Component = Memories;
      break;
    case 'reports':
    default:
      Component = Reports;
  }

  return (
    <div className="resolved-reports pl-3">
      <h3>Resolved Reports</h3>
      <div className="row reported__buttons">
        <Button
          type="button"
          theme={!type || type === 'reports' ? 'primary' : 'secondary'}
          onClick={() => navigate('/resolved/reports')}
        >
          Reports
        </Button>
        <Button
          type="button"
          theme={type === 'memories' ? 'primary' : 'secondary'}
          onClick={() => navigate('/resolved/memories')}
        >
          Memories
        </Button>
      </div>
      <div className="mt-3 card">
        <Routes>
          <Route path="/:type" element={<Component />} />
          <Route path="/" element={<Reports />} />
        </Routes>
      </div>
    </div>
  );
};

export default Resolved;
