import axios from '@app/utils/axios';

const LIMIT = 25;

export const getUsersReports = ({page = 1} = {}) => {
  return axios.post('/api/admin/reports/users', {
    pagination: {
      page,
      limit: LIMIT
    }
  });
};

export const getUserReport = (userId, {page = 1} = {}) => {
  return axios.post('/api/admin/reports/user', {
    id: userId,
    pagination: {
      page,
      limit: LIMIT
    }
  });
};

export const getMemoriesReports = ({page = 1} = {}) => {
  return axios.post('/api/admin/reports/memories', {
    pagination: {
      page,
      limit: LIMIT
    }
  });
};

export const getMemoryReport = (memoryId, {page = 1} = {}) => {
  return axios.post('/api/admin/reports/memory', {
    id: memoryId,
    pagination: {
      page,
      limit: LIMIT
    }
  });
};

export const getCommentsReports = ({page = 1} = {}) => {
  return axios.post('/api/admin/reports/comments', {
    pagination: {
      page,
      limit: LIMIT
    }
  });
};

export const getCommentReport = (id, {page = 1} = {}) => {
  return axios.post('/api/admin/reports/comment', {
    id,
    pagination: {
      page,
      limit: LIMIT
    }
  });
};

export const ignoreReport = (id) => {
  return axios.post('/api/admin/reports/ignoreReport', {
    id
  });
};

export const unignoreReport = (id) => {
  return axios.post('/api/admin/reports/unignoreReport', {
    id
  });
};

export const getIgnoredReports = ({page = 1} = {}) => {
  return axios.post('/api/admin/reports/listIgnoredReports', {
    pagination: {
      page,
      limit: LIMIT
    }
  });
};
