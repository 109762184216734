import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Paginate} from '@components';
import {getMemoriesReports} from '@app/services/reports';
import {displayName} from '@app/utils/user';

const Memories = () => {
  const [loading, setLoading] = useState(false);
  const [memoriesData, setMemoriesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const loadMemoriesData = async (page) => {
    try {
      setLoading(true);
      const res = await getMemoriesReports({page});
      setMemoriesData(res.data.data);
      setTotalPages(res.data.total_pages);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadMemoriesData(currentPage);
  }, [currentPage]);

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Memory ID</th>
            <th>Uploader (ID: email, phone, name)</th>
            <th>Times Reported</th>
            <th>First Reported</th>
            <th>Button</th>
          </tr>
        </thead>
        <tbody>
          {memoriesData.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{displayName({...row, user_id: row.owner_id})}</td>
              <td>{row.reports_count}</td>
              <td>{row.first_created}</td>
              <td>
                <Button
                  type="button"
                  onClick={() => navigate(`/reported/memories/${row.id}`)}
                >
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginate
        onPageChange={(evt) => setCurrentPage(evt.selected + 1)}
        pageCount={totalPages}
      />
    </div>
  );
};

export default Memories;
