import React, {useState, useEffect, useCallback} from 'react';
import {debounce} from 'lodash';
import {Button} from '@components';
import {getNewUsers, getUserActivities} from '@app/services/analytics';
import DatePicker from 'react-datepicker';
import SignupTable from './SignupTable';
import UserActivitiesTable from './UserActivitiesTable';

import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

const NEW_USERS = 'new_users';
const USER_ACTIVITIES = 'user_activities';

const UserActivities = () => {
  const [loading, setLoading] = useState(false);
  const [analyticData, setAnalyticData] = useState([]);
  const [type, setType] = useState(NEW_USERS);
  const [startDate, setStartDate] = useState(new Date(2021, 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [granularity, setGranularity] = useState('month');

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const fn = type === NEW_USERS ? getNewUsers : getUserActivities;
      const res = await fn({
        startDate,
        endDate,
        granularity
      });
      setAnalyticData(res.data.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }, [startDate, endDate, granularity, type]);

  const debouncedLoadData = debounce(loadData, 800);

  useEffect(() => {
    debouncedLoadData();
  }, [startDate, endDate, granularity, type]);

  const Table = type === NEW_USERS ? SignupTable : UserActivitiesTable;

  return (
    <div className="user-activities pl-3">
      <h3>User Activities</h3>
      <div className="row user-activities__buttons">
        <Button
          type="button"
          theme={type === NEW_USERS ? 'primary' : 'secondary'}
          onClick={() => setType(NEW_USERS)}
        >
          New Users
        </Button>
        <Button
          type="button"
          theme={type === USER_ACTIVITIES ? 'primary' : 'secondary'}
          onClick={() => setType(USER_ACTIVITIES)}
        >
          Actions
        </Button>
      </div>
      <div className="row user-activities__dates mt-3 mx-0">
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
        />
        <DatePicker
          dateFormat="yyyy-MM-dd"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
        />
      </div>
      <div className="row user-activities__granularity mt-3 mx-0">
        <Button
          type="button"
          theme={granularity === 'day' ? 'primary' : 'secondary'}
          onClick={() => setGranularity('day')}
        >
          Daily
        </Button>
        <Button
          type="button"
          theme={granularity === 'week' ? 'primary' : 'secondary'}
          onClick={() => setGranularity('week')}
        >
          Weekly
        </Button>
        <Button
          type="button"
          theme={granularity === 'month' ? 'primary' : 'secondary'}
          onClick={() => setGranularity('month')}
        >
          Monthly
        </Button>
      </div>
      <div className="user-activites__table mt-3 card">
        {loading ? (
          <div className="my-5 d-flex justify-content-center align-items-center">
            <div className="spinner-border " role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Table data={analyticData} />
        )}
      </div>
    </div>
  );
};

export default UserActivities;
