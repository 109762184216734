import React from 'react';

const Dashboard = () => {
  return (
    <div className="container-fluid">
      <h5 className="mb-2">Admin Panel</h5>
    </div>
  );
};

export default Dashboard;
