import React from 'react';
import ReactPaginate from 'react-paginate';
import './index.css';

const Paginate = (props) => {
  return (
    <ReactPaginate
      className="custom-paginate"
      breakLabel="..."
      nextLabel="Next >"
      previousLabel="< Previous"
      renderOnZeroPageCount={null}
      pageRangeDisplayed={5}
      activeClassName="custom-paginate__active"
      {...props}
    />
  );
};

export default Paginate;
