import React, {useState, useEffect} from 'react';
import {listUsers, unbanUser} from '@app/services/bans';
import {displayName} from '@app/utils/user';
import {Button, Paginate} from '@components';

const BannedUsers = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loadData = async (page) => {
    try {
      setLoading(true);
      const res = await listUsers({page});
      setData(res.data.data);
      setTotalPages(res.data.total_pages);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData(currentPage);
  }, [currentPage]);

  const unban = async (row) => {
    const ans = window.confirm(
      `Unban user ${row.user_id}? This cannot be undone`
    );
    if (!ans) return;

    try {
      await unbanUser(row.user_id);
    } catch (err) {
      console.log(err);
    }
    window.location.reload();
  };

  return (
    <div className="banned-users pl-3">
      <h3>Banned Users</h3>

      {loading ? (
        <div className="my-5 d-flex justify-content-center align-items-center">
          <div className="spinner-border " role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Display Name (ID: email, phone, name)</th>
                <th>Banned DateTime</th>
                <th>Reasons for Banning</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row) => (
                <tr key={row.id}>
                  <td>{row.user_id}</td>
                  <td>{displayName(row)}</td>
                  <td>{row.created}</td>
                  <td>{row.reason}</td>
                  <td>
                    <Button
                      type="button"
                      theme="danger"
                      onClick={() => unban(row)}
                    >
                      Unban
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginate
            onPageChange={(evt) => setCurrentPage(evt.selected + 1)}
            pageCount={totalPages}
            forcePage={currentPage - 1}
          />
        </>
      )}
    </div>
  );
};

export default BannedUsers;
