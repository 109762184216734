import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {
  getMemoryReport,
  ignoreReport,
  unignoreReport
} from '@app/services/reports';
import {getMemory, blockMemory} from '@app/services/memories';
import {getUserDetail} from '@app/services/user';
import {Button, BanUser, Paginate} from '@components';
import {displayName} from '@app/utils/user';

const Memory = () => {
  const {id: memoryId} = useParams();
  const [reports, setReports] = useState([]);
  const [memoryDetail, setMemoryDetail] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [showBan, setShowBan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loadReports = async (page) => {
    try {
      const res = await getMemoryReport(memoryId, {page});
      setReports(res.data.data);
      setTotalPages(res.data.total_pages);
    } catch (err) {
      console.log(err);
    }
  };
  const loadMemoryDetail = async () => {
    try {
      const res = await getMemory(memoryId);
      setMemoryDetail(res.data);
      const userRes = await getUserDetail(res.data.owner_id);
      setUserDetail(userRes.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadReports(currentPage);
    loadMemoryDetail();
  }, [currentPage]);

  const toggleIgnore = async (report) => {
    try {
      if (report.ignored_at) {
        await unignoreReport(report.id);
      } else {
        await ignoreReport(report.id);
      }
      window.location.reload();
    } catch (err) {
      alert(err);
    }
  };

  const clickBlock = async () => {
    try {
      await blockMemory(memoryId);
      window.location.assign('/reported/memories');
    } catch (err) {
      alert(err);
    }
  };

  const onCompleteBan = () => {
    window.location.reload();
  };

  return (
    <div className="reported-user px-3 py-3">
      <div className="reported-user__user-detail">
        <div>Memory ID: {memoryId}</div>
        <div>
          Uploader Name:{' '}
          {`${memoryDetail.first_name || ''} ${
            memoryDetail.last_name || ''
          }`.trim()}
        </div>
        <div>Email: {memoryDetail.email}</div>
        <div>Number: {memoryDetail.number}</div>
        <div>Memory: {memoryDetail.memory}</div>
      </div>
      <div>
        <Button
          theme="danger"
          className="my-1"
          type="button"
          onClick={() => clickBlock()}
        >
          Block memory
        </Button>
      </div>
      <div className="reported-user__images">
        <h4>Images</h4>
        <div className="reported-user__images-body">
          {memoryDetail.cover_url && (
            <div className="reported-user__image">
              <a href={memoryDetail.cover_url} target="_blank" rel="noreferrer">
                <img src={memoryDetail.cover_url} alt="Cover" />
              </a>
            </div>
          )}
          {memoryDetail.resources?.map((resource) => (
            <div className="reported-user__image" key={resource.id}>
              <a href={resource.url} target="_blank" rel="noreferrer">
                {resource.type === 'image' && (
                  <img src={resource.url} alt="Resource" />
                )}
                {resource.type === 'video' && (
                  <video
                    src={resource.url}
                    alt="Resource"
                    muted
                    autoPlay={false}
                    playsInline
                    controls
                  />
                )}
              </a>
            </div>
          ))}
        </div>
      </div>
      <table className="table table-bordered mt-3">
        <thead>
          <tr>
            <th>Date Reported</th>
            <th>Reported By (ID: email, phone, name)</th>
            <th>Reason</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report) => (
            <tr key={report.id}>
              <td>{report.created}</td>
              <td>{displayName({...report, user_id: report.reporter_id})}</td>
              <td>{report.remarks}</td>
              <td>
                <Button type="button" onClick={() => toggleIgnore(report)}>
                  {report.ignored_at ? 'Undo ignore' : 'Ignore'}
                </Button>
                {!userDetail.isBanned && (
                  <Button
                    className="ml-1"
                    type="button"
                    theme="danger"
                    onClick={() => setShowBan(true)}
                  >
                    Ban user
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginate
        onPageChange={(evt) => setCurrentPage(evt.selected + 1)}
        pageCount={totalPages}
      />
      <BanUser
        userId={memoryDetail.owner_id}
        show={showBan}
        onHide={() => setShowBan(false)}
        onComplete={onCompleteBan}
      />
    </div>
  );
};

export default Memory;
