import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {Button} from '@components';
import {banUser} from '@app/services/bans';

const BanUser = ({userId, show, onHide, onComplete}) => {
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');

  const clickBan = async () => {
    setProcessing(true);
    try {
      await banUser(userId, reason);
    } catch (err) {
      console.log(err);
      alert(err);
    }
    setProcessing(false);
    onHide();
    onComplete();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Ban user</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form>
          <div className="form-group">
            <label htmlFor="ban-ser-reason">Reasons</label>
            <textarea
              className="form-control"
              rows="4"
              id="ban-user-reason"
              placeholder="Reasons"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onHide} disabled={processing}>
          Close
        </Button>
        <Button
          onClick={clickBan}
          theme="danger"
          disabled={processing || !reason.trim()}
        >
          Ban user
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BanUser;
