import React, {useState, useEffect} from 'react';
import {Routes, Route, useNavigate, useParams} from 'react-router-dom';
import {Button} from '@components';
import Users from './Users';
import User from './User';
import Memories from './Memories';
import Memory from './Memory';
import Comments from './Comments';
import Comment from './Comment';

import './index.css';

const Reported = () => {
  const navigate = useNavigate();
  const {'*': path} = useParams();

  const [entityType, entityId] = path.split('/');

  let Component;
  if (entityId) {
    switch (entityType) {
      case 'memories':
        Component = Memory;
        break;
      case 'comments':
        Component = Comment;
        break;
      case 'users':
      default:
        Component = User;
    }
  } else {
    switch (entityType) {
      case 'memories':
        Component = Memories;
        break;
      case 'comments':
        Component = Comments;
        break;
      case 'users':
      default:
        Component = Users;
    }
  }

  return (
    <div className="reported pl-3">
      <h3>Reported</h3>
      <div className="row reported__buttons">
        <Button
          type="button"
          theme={
            !entityType || entityType === 'users' ? 'primary' : 'secondary'
          }
          onClick={() => navigate('/reported/users')}
        >
          Users
        </Button>
        <Button
          type="button"
          theme={entityType === 'memories' ? 'primary' : 'secondary'}
          onClick={() => navigate('/reported/memories')}
        >
          Memories
        </Button>
        <Button
          type="button"
          theme={entityType === 'comments' ? 'primary' : 'secondary'}
          onClick={() => navigate('/reported/comments')}
        >
          Comments
        </Button>
      </div>
      <div className="mt-3 card">
        <Routes>
          <Route path="/:type/:id" element={<Component />} />
          <Route path="/:type" element={<Component />} />
          <Route path="/" element={<Users />} />
        </Routes>
      </div>
    </div>
  );
};

export default Reported;
