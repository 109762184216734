import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Paginate} from '@components';
import {getBlockedMemories, unblockMemory} from '@app/services/memories';
import {displayName} from '@app/utils/user';

import './Memories.css';

const Memories = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const loadMemoriesData = async (page) => {
    try {
      setLoading(true);
      const res = await getBlockedMemories({page});
      setData(res.data.data);
      setTotalPages(res.data.total_pages);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadMemoriesData(currentPage);
  }, [currentPage]);

  const renderEntity = (row) => {
    switch (row.entity) {
      case 'user':
        return displayName({...row.user, user_id: row.user.id});
      case 'memory':
        return (
          <div>
            Name: {row.memory.name} <br />
            Description: {row.memory.description}
          </div>
        );
      case 'comment':
        return <div>{row.comment.comment}</div>;
      default:
        return '';
    }
  };

  const clickUndo = async (memory) => {
    try {
      await unblockMemory(memory.id);
      window.location.reload();
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div>
      <table className="table table-bordered blocked-memories-table">
        <thead>
          <tr>
            <th>Memory ID</th>
            <th>Uploader (ID: email, phone, name)</th>
            <th>Name</th>
            <th>Description</th>
            <th>Cover</th>
            <th>Button</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{displayName({...row.user, user_id: row.owner_id})}</td>
              <td>{row.name}</td>
              <td>{row.description}</td>
              <td>
                {row.cover_url && (
                  <div className="blocked-memories__image">
                    <img src={row.cover_url} alt={row.name} />
                  </div>
                )}
              </td>
              <td>
                <Button type="button" onClick={() => clickUndo(row)}>
                  Unblock memory
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginate
        onPageChange={(evt) => setCurrentPage(evt.selected + 1)}
        pageCount={totalPages}
      />
    </div>
  );
};

export default Memories;
