import axios from '@app/utils/axios';

export const getNewUsers = ({
  granularity = 'month',
  startDate = new Date(2021, 0, 1).toISOString(),
  endDate = new Date().toISOString()
} = {}) => {
  return axios.get('/api/admin/analytics/newUsers', {
    params: {granularity, startDate, endDate}
  });
};

export const getUserActivities = ({
  granularity = 'month',
  startDate = new Date(2021, 0, 1).toISOString(),
  endDate = new Date().toISOString()
} = {}) => {
  return axios.get('/api/admin/analytics/userActivities', {
    params: {granularity, startDate, endDate}
  });
};
