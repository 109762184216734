import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Paginate} from '@components';
import {getCommentsReports} from '@app/services/reports';
import {displayName} from '@app/utils/user';

const Comments = () => {
  const [loading, setLoading] = useState(false);
  const [commentsData, setCommentsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const loadCommentsData = async (page) => {
    try {
      setLoading(true);
      const res = await getCommentsReports({page});
      setCommentsData(res.data.data);
      setTotalPages(res.data.total_pages);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadCommentsData(currentPage);
  }, [currentPage]);

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Comment ID</th>
            <th>Commenter (ID: email, phone, name)</th>
            <th>Times Reported</th>
            <th>First Reported</th>
            <th>Button</th>
          </tr>
        </thead>
        <tbody>
          {commentsData.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>{displayName({...row, user_id: row.commenter_id})}</td>
              <td>{row.reports_count}</td>
              <td>{row.first_created}</td>
              <td>
                <Button
                  type="button"
                  onClick={() => navigate(`/reported/comments/${row.id}`)}
                >
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginate
        onPageChange={(evt) => setCurrentPage(evt.selected + 1)}
        pageCount={totalPages}
      />
    </div>
  );
};

export default Comments;
