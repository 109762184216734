import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {
  getCommentReport,
  ignoreReport,
  unignoreReport
} from '@app/services/reports';
import {getComment} from '@app/services/comments';
import {getUserDetail} from '@app/services/user';
import {Button, BanUser, Paginate} from '@components';
import {displayName} from '@app/utils/user';

const User = () => {
  const {id: commentId} = useParams();
  const [reports, setReports] = useState([]);
  const [commentDetail, setCommentDetail] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [showBan, setShowBan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const loadReports = async (page) => {
    try {
      const res = await getCommentReport(commentId, {page});
      setReports(res.data.data);
      setTotalPages(res.data.total_pages);
    } catch (err) {
      console.log(err);
    }
  };
  const loadCommentDetail = async () => {
    try {
      const res = await getComment(commentId);
      setCommentDetail(res.data);
      const userRes = await getUserDetail(res.data.commenter_id);
      setUserDetail(userRes.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadReports(currentPage);
    loadCommentDetail();
  }, [currentPage]);

  const toggleIgnore = async (report) => {
    try {
      if (report.ignored_at) {
        await unignoreReport(report.id);
      } else {
        await ignoreReport(report.id);
      }
      window.location.reload();
    } catch (err) {
      alert(err);
    }
  };

  const onCompleteBan = () => {
    window.location.reload();
  };

  return (
    <div className="reported-user px-3 py-3">
      <div className="reported-user__user-detail">
        <div>Comment ID: {commentId}</div>
        <div>
          Commentor Name:{' '}
          {`${commentDetail.first_name || ''} ${
            commentDetail.last_name || ''
          }`.trim()}
        </div>
        <div>Email: {commentDetail.email}</div>
        <div>Number: {commentDetail.number}</div>
        <div>Comment: {commentDetail.comment}</div>
      </div>
      <table className="table table-bordered mt-3">
        <thead>
          <tr>
            <th>Date Reported</th>
            <th>Reported By (ID: email, phone, name)</th>
            <th>Reason</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {reports.map((report) => (
            <tr key={report.id}>
              <td>{report.created}</td>
              <td>{displayName({...report, user_id: report.reporter_id})}</td>
              <td>{report.remarks}</td>
              <td>
                <Button type="button" onClick={() => toggleIgnore(report)}>
                  {report.ignored_at ? 'Undo ignore' : 'Ignore'}
                </Button>
                {!userDetail.isBanned && (
                  <Button
                    className="ml-1"
                    type="button"
                    theme="danger"
                    onClick={() => setShowBan(true)}
                  >
                    Ban user
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginate
        onPageChange={(evt) => setCurrentPage(evt.selected + 1)}
        pageCount={totalPages}
      />
      <BanUser
        userId={commentDetail.commenter_id}
        show={showBan}
        onHide={() => setShowBan(false)}
        onComplete={onCompleteBan}
      />
    </div>
  );
};

export default User;
