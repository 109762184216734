import axios from '@app/utils/axios';

export const banUser = (id, reason) => {
  return axios.post('/api/admin/bans/banUser', {user_id: id, reason});
};

export const unbanUser = (id) => {
  return axios.post('/api/admin/bans/unbanUser', {user_id: id});
};

export const listUsers = ({page = 1} = {}) => {
  return axios.post('/api/admin/bans/listUsers', {
    pagination: {
      page,
      limit: 25
    }
  });
};
