import axios from '@app/utils/axios';

const LIMIT = 25;

export const getMemory = (id) => {
  return axios.post('/api/admin/memories/getMemory', {id});
};

export const unblockMemory = (id) => {
  return axios.post('/api/admin/memories/unblockMemory', {
    id
  });
};

export const blockMemory = (id) => {
  return axios.post('/api/admin/memories/blockMemory', {
    id
  });
};

export const getBlockedMemories = ({page = 1} = {}) => {
  return axios.post('/api/admin/memories/listBlockedMemories', {
    pagination: {
      page,
      limit: LIMIT
    }
  });
};
