import React from 'react';

const SignupTable = ({data}) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Date</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={`signup-${row.date}`}>
            <td>{row.date}</td>
            <td>{row.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SignupTable;
