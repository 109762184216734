import axios from '@app/utils/axios';

export const getProfile = async () => {
  const res = await axios.get('/api/users/show');
  return res.data;
};

export const getUserDetail = (userId) => {
  return axios.post('/api/admin/users/getBasicInfo', {user_id: userId});
};
