export const displayName = ({
  user_id: userId,
  first_name: firstName,
  last_name: lastName,
  email,
  number
}) => {
  return `${userId}: ${email}, ${number}, ${firstName || ''} ${
    lastName || ''
  }`.trim();
};
