import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Paginate} from '@components';
import {getIgnoredReports, unignoreReport} from '@app/services/reports';
import {displayName} from '@app/utils/user';

const Reports = () => {
  const [loading, setLoading] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const loadReportsData = async (page) => {
    try {
      setLoading(true);
      const res = await getIgnoredReports({page});
      setReportsData(res.data.data);
      setTotalPages(res.data.total_pages);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadReportsData(currentPage);
  }, [currentPage]);

  const renderEntity = (row) => {
    switch (row.entity) {
      case 'user':
        return displayName({...row.user, user_id: row.user.id});
      case 'memory':
        return (
          <div>
            Name: {row.memory.name} <br />
            Description: {row.memory.description}
          </div>
        );
      case 'comment':
        return <div>{row.comment.comment}</div>;
      default:
        return '';
    }
  };

  const clickUndo = async (report) => {
    try {
      if (!report.ignored_at) {
        return;
      }
      await unignoreReport(report.id);
      window.location.reload();
    } catch (err) {
      alert(err);
    }
  };

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Report ID</th>
            <th>Reporter (ID: email, phone, name)</th>
            <th>Reason</th>
            <th>Type</th>
            <th>Reported item</th>
            <th>Button</th>
          </tr>
        </thead>
        <tbody>
          {reportsData.map((row) => (
            <tr key={row.id}>
              <td>{row.id}</td>
              <td>
                {displayName({...row.reporter, user_id: row.reporter_id})}
              </td>
              <td>{row.remarks}</td>
              <td>{row.entity}</td>
              <td>{renderEntity(row)}</td>
              <td>
                <Button type="button" onClick={() => clickUndo(row)}>
                  Undo ignore
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Paginate
        onPageChange={(evt) => setCurrentPage(evt.selected + 1)}
        pageCount={totalPages}
      />
    </div>
  );
};

export default Reports;
